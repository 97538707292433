import React, { useState } from "react";
import Select, { components } from "react-select";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import cx from "clsx";
import { useTranslation } from "react-i18next";

interface SelectCountryProps {
  onCountryChange: (country: string) => void;
}

const CountryOptions = [
  { value: "DE", label: "Germany" },
  { value: "BE", label: "Belgium" },
  { value: "FR", label: "France" },
];

const SelectCountry: React.FC<SelectCountryProps> = ({ onCountryChange }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <div data-testid={"country"} className="flex flex-col w-full font-medium">
      <span className="inline-flex items-center justify-between mb-1">
        <label htmlFor={"countryCode"} className="text-sm text-gray-700">
          {t("inputs.country")}
        </label>
      </span>

      <Controller
        name="countryCode"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Select
            data-testid={"country"}
            options={CountryOptions}
            value={CountryOptions.find((option) => option.value === value)}
            onChange={(selectedOption) => {
              const selectedValue = selectedOption?.value;
              onChange(selectedValue);
              onCountryChange(selectedValue || "");
            }}
            isSearchable
            isLoading={isLoading}
            placeholder={t("placeholders.country")}
            noOptionsMessage={() => t("No countries found.")}
            loadingMessage={() => t("Loading...")}
            styles={{
              control: (base, state) => ({
                ...base,
                backgroundColor: "white",
                boxShadow: "none",
                borderColor: errors["countryCode"]
                  ? "#E53E3E"
                  : state.isFocused
                  ? "#CDF564"
                  : "#d4d7dc",
                borderRadius: "1.375rem",
                ":hover": {
                  borderColor: state.isFocused
                    ? "#CDF564"
                    : errors["countryCode"]
                    ? "#E53E3E"
                    : "#d4d7dc",
                },
              }),
              placeholder: (base) => ({
                ...base,
                color: "#9ca3ae",
                fontSize: "0.91em",
              }),
              option: (base, state) => ({
                ...base,
                cursor: "pointer",
                backgroundColor: state.isFocused ? "#f5f5f5" : "#faf8f7",
                color: "black",
                ":active": {
                  backgroundColor: "#CDD905",
                },
              }),
              singleValue: (base) => ({
                ...base,
                color: "#4A5568",
                fontFamily: "inherit",
                fontSize: "0.875rem",
              }),
              indicatorsContainer: (base) => ({
                ...base,
                display: "flex",
                alignItems: "center",
                paddingRight: "2px",
                "& > div": {
                  marginRight: "8px",
                  color: "grey",
                },
              }),
              menu: (base) => ({
                ...base,
                backgroundColor: "#faf8f7",
                borderRadius: "0.375rem",
                borderColor: "#CDF564",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                marginTop: "0",
                zIndex: 50,
              }),
              menuList: (provided) => ({
                ...provided,
                backgroundColor: "#faf8f7",
                borderRadius: "0.375rem",
                padding: "5px",
                maxHeight: "300px",
                overflowY: "auto",
                "::-webkit-scrollbar": {
                  width: "5px",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "#f5f5f5",
                },
                "::-webkit-scrollbar-thumb": {
                  backgroundColor: "#ccc",
                  borderRadius: "5px",
                },
              }),
            }}
            components={{
              IndicatorSeparator: null,
              Option,
              SingleValue,
            }}
          />
        )}
      />

      <div className="min-h-[20px]">
        <ErrorMessage
          name="countryCode"
          render={({ message }) => (
            <div className="text-xs text-red-500">{message}</div>
          )}
        />
      </div>
    </div>
  );
};

export default SelectCountry;

const Option = (props: any) => (
  <components.Option
    {...props}
    className={cx([
      "block w-full text-left px-4 py-2 text-sm",
      props.isFocused && "bg-gray-100 text-gray-900",
      props.isSelected && "bg-gray-200 text-gray-900",
    ])}
  >
    {props?.data?.label}
  </components.Option>
);

const SingleValue = (props: any) => (
  <components.SingleValue {...props}>
    {props?.data?.label}
  </components.SingleValue>
);
