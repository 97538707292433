import React, { useCallback, useEffect, useRef, useState } from "react";
import Autosuggest from "react-autosuggest";
import axios from "axios";
import { debounce } from "../../utils/debounce";
import { Spinner } from "../../icons/Spinner";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import { Check } from "../../icons/Check";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import toast from "react-hot-toast";
import clsx from "clsx";

interface Company {
  cocNumber: string;
  companyName: string;
  city: string;
}

export interface ICompanySearch {
  isNetherlands: boolean;
  name: string;
  placeholder?: string;
  label?: string;
  selectedCountry?: string;
}

const CompanySearch: React.FC<ICompanySearch> = ({
  isNetherlands,
  name,
  placeholder,
  label,
  selectedCountry,
}) => {
  const {
    formState: { errors },
    setValue,
  } = useFormContext();

  const { t } = useTranslation();
  const [value, setValuee] = useState<string>("");
  const [suggestions, setSuggestions] = useState<Company[]>([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState<boolean>(false);
  const [isValidSuggestionSelected, setIsValidSuggestionSelected] =
    useState<boolean>(false);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const wasBlurred = useRef(false);

  useEffect(() => {
    setIsValidSuggestionSelected(false);
    setValuee("");
  }, [isNetherlands, t, selectedCountry]);

  const debouncedGetSuggestions = useCallback(
    debounce(async (searchTerm: string) => {
      if (searchTerm.length < 2) return;

      setLoadingSuggestions(true);
      const token = executeRecaptcha && (await executeRecaptcha());
      if (!token) {
        toast.error("Failed to get reCAPTCHA token");
        setLoadingSuggestions(false);
        return;
      }

      axios
        .get(`${process.env.REACT_APP_API}/v1/companysearch`, {
          headers: { accept: "*/*" },
          params: {
            searchTerm,
            page: 1,
            recaptchaToken: token,
            countryCode: isNetherlands ? "NL" : selectedCountry,
          },
        })
        .then((response) => {
          !!response.data.result && setSuggestions(response.data.result);
          setLoadingSuggestions(false);
        })
        .catch((error) => {
          console.error(error);
          setLoadingSuggestions(false);
        });
    }, 700),
    [executeRecaptcha, isNetherlands, selectedCountry]
  );

  const onSuggestionSelected = (
    _event: React.FormEvent,
    { suggestion }: { suggestion: Company }
  ) => {
    setValue(name, suggestion);
    setIsValidSuggestionSelected(true);
  };

  const onSuggestionsClearRequested = () => {
    setLoadingSuggestions(false);
    setSuggestions([]);
  };

  const onChange = (
    _event: React.FormEvent,
    { newValue, method }: { newValue: string; method: string }
  ) => {
    setValuee(newValue);
    setIsValidSuggestionSelected(false);
    setValue(name, null);

    if (method === "type") {
      wasBlurred.current = false;
      setSuggestions([]);
      if (newValue.length >= 2) {
        setLoadingSuggestions(true);
        debouncedGetSuggestions(newValue);
      } else {
        setLoadingSuggestions(false);
        setSuggestions([]);
        setValue(name, null);
      }
    }
  };

  const inputProps = {
    placeholder,
    value,
    onChange,
    className: clsx(
      "bg-white w-full rounded-full border px-4 py-2 text-sm transition-all duration-300 ease-in-out",
      errors[name]
        ? "border-red-500 focus:border-red-500"
        : "border-gray-300 focus:border-primary-500",
      "focus:outline-none focus:border-1 focus:border-primary-500"
    ),
    onBlur: () => (wasBlurred.current = true),
    onFocus: () => (wasBlurred.current = false),
  };

  return (
    <>
      {loadingSuggestions && (
        <div className="absolute h-10 z-50 bg-tertiary-100 top-[70px] w-full flex items-center justify-center rounded-xl">
          <Spinner width={20} height={20} />
        </div>
      )}
      <div className="flex flex-col w-full font-medium">
        <span className="inline-flex items-center justify-between mb-1">
          <label htmlFor={name} className="text-sm text-gray-700">
            {label}
          </label>
        </span>

        {isValidSuggestionSelected && (
          <div className="absolute right-2 top-[33px] z-10">
            <Check className="w-5 h-5 text-primary-500 font-extrabold transition-all" />
          </div>
        )}

        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={({ value }) => {
            if (wasBlurred.current) return;

            if (value.length >= 2) {
              debouncedGetSuggestions(value);
            } else {
              setSuggestions([]);
            }
          }}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={(suggestion) => suggestion.companyName}
          renderSuggestion={(suggestion) => (
            <div>
              {suggestion.companyName} ({suggestion.cocNumber}),{" "}
              {suggestion.city}
            </div>
          )}
          inputProps={inputProps}
          onSuggestionSelected={onSuggestionSelected}
          shouldRenderSuggestions={(value) => value.length >= 2}
          theme={{
            container: { position: "relative", width: "100%" },
            inputFocused: { outline: "none" },
            suggestionsContainerOpen: {
              display: "block",
              position: "absolute",
              width: "100%",
              border: "1px solid #eee",
              backgroundColor: "#fff",
              fontFamily: "inherit",
              fontSize: "0.875rem",
              zIndex: 50,
              maxHeight: "300px",
              overflow: "auto",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
              top: "50px",
            },
            suggestionsList: {
              margin: "1px",
              padding: "5px",
              listStyleType: "none",
            },
            suggestion: {
              cursor: "pointer",
              padding: "6px 10px",
            },
            suggestionHighlighted: {
              backgroundColor: "#F5F5F5",
            },
            sectionContainer: {
              borderTop: "1px solid #eee",
            },
            sectionContainerFirst: {
              borderTop: "0",
            },
            sectionTitle: {
              padding: "0 0 0 10px",
              fontSize: "12px",
              color: "#777",
            },
          }}
        />

        <div className="min-h-[20px]">
          <ErrorMessage
            name={name}
            render={({ message }) => (
              <div className="text-xs text-red-500">{message}</div>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default CompanySearch;
