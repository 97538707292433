import React, { FC } from "react";

export interface ICheck {
  className: string;
}

export const Check: FC<ICheck> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="4"
      stroke="white"
      className={`${className} bg-primary-500 rounded-full p-1`}
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="m4.5 12.75 6 6 9-13.5"
      />
    </svg>
  );
};
