import { t } from "i18next";
import React from "react";
import { formatPrice } from "../utilities/formatPrice";

interface EditBundleCardProps {
  range: number;
  pricePerMonth: number;
  selected: boolean;
  transactionPrice: number;
  extraTransactionPrice: number;
  onSelect: () => void;
}

const EditBundleCard: React.FC<EditBundleCardProps> = ({
  range,
  pricePerMonth,
  selected,
  transactionPrice,
  onSelect,
}) => {
  return (
    <div
      className={`border p-4 rounded-2xl shadow-md hover:shadow-xl transition-all duration-300 cursor-pointer ${
        selected
          ? "bg-primary-100 border-primary-500"
          : "bg-white border-gray-300"
      } w-[140px] h-[160px] md:w-[230px] md:h-[110px]`}
      onClick={onSelect}>
      <div className="text-gray-600 text-start flex flex-col justify-center h-full">
        <h2 className="text-sm sm:text-lg font-bold  text-gray-700 mb-1">
          {t("pos.bundle.bundle")}:{" "}
          {`${range === 0 ? `${t("pos.bundle.noBundle")}` : range}`}{" "}
          {t("pos.bundle.trx")}
        </h2>
        {range !== 0 && (
          <div className="text-sm sm:text-md font-semibold text-gray-600 mb-1">
            {t("pos.bundle.price")}: €{formatPrice(pricePerMonth)} /
            {t("pos.review.bundle.perMonth")}
          </div>
        )}
        <div>
          {transactionPrice ? (
            <p className="text-sm sm:text-md font-semibold text-gray-600 mb-1">
              {`${t("pos.bundle.transaction")}: €${transactionPrice}`}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EditBundleCard;
