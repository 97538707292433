import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { bundleTransactions } from "../../data/bundleData";
import BundleCard from "../../components/pos/cards/BundleCard";
import { PosContext } from "../../context/PosContext";
import { t } from "i18next";
import useCustomNavigate from "../../components/pos/hooks/useCustomNavigate";
import { formatPrice } from "../../components/pos/utilities/formatPrice";
import Tooltip from "../../components/ui/Tooltip";
import { IBundleTransaction } from "../../interfaces/IBundle";

const Bundle: React.FC = () => {
  const { selectedBundle, setSelectedBundle, updateSteps } =
    React.useContext(PosContext);
  const navigate = useCustomNavigate();
  const [searchParams] = useSearchParams();
  const country = searchParams.get("country");

  const isBelgium = country === "BE";

  useEffect(() => {
    if (isBelgium) {
      const noBundle: IBundleTransaction | undefined = bundleTransactions.find(
        (bundle) => bundle.range === 0
      );
      if (noBundle) {
        setSelectedBundle(noBundle);
      }
    }
  }, [isBelgium, setSelectedBundle]);

  const handleContinue = () => {
    updateSteps({ bundle: true });
    navigate("/pos/accessories");
  };

  const handleBack = () => {
    navigate("/pos/subscription");
  };

  return (
    <div className="container mx-auto px-4 py-8 pt-4 bg-white w-full md:max-w-3xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl">
      <div
        className="flex items-center text-primary-500 cursor-pointer mb-6"
        onClick={handleBack}
      >
        <svg
          className="w-6 h-6 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 19l-7-7 7-7"
          ></path>
        </svg>
        {t("pos.buttons.back.name")}
      </div>

      {isBelgium ? (
        <div className="flex justify-center items-center h-64">
          <div
            className="text-gray-800 text-xl mb-4 p-6 bg-gray-100 rounded-xl shadow-lg border border-primary-300 
                            hover:shadow-2xl transition-all duration-300"
          >
            <p className="text-center font-semibold">
              {t("pos.bundle.disabledForBelgium")}
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:w-2/3 pr-4">
              <div className="flex justify-start">
                <h1 className="text-3xl font-bold mb-2 relative">
                  {t("pos.bundle.title")}
                </h1>
                <Tooltip
                  content={
                    <div className="w-80 md:w-96 text-primary-800 font-semibold">
                      {t("pos.bundle.tooltip")}
                    </div>
                  }
                  direction="bottom"
                  tooltipClasses="bg-primary-200 text-primary-800 shadow-lg p-3 text-lg"
                  arrowClasses="bg-primary-800"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-6 h-6 ml-1 absolute text-primary-600 hover:text-primary-700 transition-all duration-200 cursor-pointer"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                    />
                  </svg>
                </Tooltip>
              </div>
              <div className="text-gray-800 text-xl mb-4">
                <p>{t("pos.bundle.p1")}</p>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 lg:mb-0">
                {bundleTransactions.map((bundle, index) => (
                  <BundleCard
                    key={index}
                    range={bundle.range}
                    pricePerMonth={bundle.pricePerMonth}
                    transactionPrice={bundle.transactionPrice || 0}
                    selected={selectedBundle?.range === bundle.range}
                    onSelect={() => setSelectedBundle(bundle)}
                  />
                ))}
              </div>
            </div>
            <div className="border border-gray-500 sm:mx-10 mt-10"></div>
            <div className="relative flex justify-center items-start mr-18 md:mr-0 lg:border-0 lg:w-1/3 md:mt-0">
              <div className="p-8 mb-10 mt-4 md:mt-24">
                <h2 className="text-2xl font-bold mb-4">
                  {t("pos.bundle.p3")}
                </h2>
                {selectedBundle && (
                  <div className="text-xl">
                    <div className="mb-4 flex justify-center lg:justify-start">
                      <div className="p-2 bg-gray-100 border border-primary-500 rounded-md shadow-md relative min-w-[320px] md:min-w-[360px]">
                        {selectedBundle.range !== 0 ? (
                          <p className="font-semibold text-lg">
                            {t("pos.bundle.transactionPrice")}:{" "}
                            <span className="line-through text-red-500">
                              €0,11
                            </span>{" "}
                            <span className="text-primary-600 font-bold">
                              €
                              {formatPrice(
                                selectedBundle.transactionPrice || ""
                              )}
                            </span>
                            <div className="absolute bottom-8 md:bottom-4 -right-6 md:right-0 mt-1 mr-1 bg-red-500 text-white font-bold py-1 px-1 rounded-full text-xs shadow-lg">
                              - {selectedBundle.discount}%
                            </div>
                          </p>
                        ) : (
                          <p className="font-semibold text-lg text-center">
                            {t("pos.bundle.noBundle2")}
                          </p>
                        )}
                      </div>
                    </div>
                    {selectedBundle.range !== 0 && (
                      <p>
                        <span className="font-semibold  text-gray-600">
                          {t("pos.bundle.bundle")}:{" "}
                        </span>{" "}
                        {selectedBundle.range} {t("pos.bundle.transactions")}
                      </p>
                    )}
                    {selectedBundle.range !== 0 && (
                      <p>
                        <span className="font-semibold  text-gray-600">
                          {t("pos.bundle.price")}:{" "}
                        </span>{" "}
                        €{formatPrice(selectedBundle.pricePerMonth)} /{" "}
                        {t("pos.bundle.month")}
                      </p>
                    )}
                    {selectedBundle.range === 0 && (
                      <p>
                        <span className="font-semibold  text-gray-600">
                          {t("pos.bundle.transactionFee")}:{" "}
                        </span>{" "}
                        €{formatPrice(selectedBundle.pricePerMonth)}
                      </p>
                    )}
                    <p className="mb-2">
                      <span className="font-semibold  text-gray-600">
                        {t("pos.bundle.applies")}
                      </span>{" "}
                      {t("pos.bundle.applies2")}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <div className="flex justify-end">
        <button
          onClick={handleContinue}
          className="mt-4 bg-primary-500 text-black font-bold py-2 px-4 rounded hover:bg-primary-600 transition duration-300"
        >
          {t("pos.buttons.continue.name")}
        </button>
      </div>
    </div>
  );
};

export default Bundle;
