import React, { useContext } from "react";
import { PosContext } from "../../context/PosContext";
import OrderReviewProduct from "../../components/pos/orderReview/OrderReviewProduct";
import OrderReviewAccessory from "../../components/pos/orderReview/OrderReviewAccessory";
import { IOrder, ISelectedAccessory } from "../../interfaces/IPos";
import useCustomNavigate from "../../components/pos/hooks/useCustomNavigate";
import { t } from "i18next";
import DeliveryLocation from "../../components/pos/ui/DeliveryLocation";
import { formatPrice } from "../../components/pos/utilities/formatPrice";
import { useDetermineOrderType } from "../../components/pos/hooks/useDetermineOrderType";
import toast from "react-hot-toast";
import OrderReviewBundle from "../../components/pos/orderReview/OrderReviewBundle";

const OrderReview: React.FC = () => {
  const navigate = useCustomNavigate();
  const {
    orders,
    addNewOrder,
    updateSteps,
    updateQuantity,
    aggregatedAccessories,
    updateOrderType,
    deliveryAddress,
  } = useContext(PosContext);
  const orderType = useDetermineOrderType();

  const handleAddNewOrder = () => {
    addNewOrder();
    navigate("/pos/terminal");
  };

  const handleFinish = () => {
    const isDeliveryAddressComplete =
      deliveryAddress &&
      deliveryAddress?.street?.length > 0 &&
      deliveryAddress?.houseNumber?.length > 0 &&
      deliveryAddress?.postalCode?.length > 0 &&
      deliveryAddress?.city?.length > 0 &&
      deliveryAddress?.country?.length > 0;

    if (isDeliveryAddressComplete) {
      updateSteps({ review: true });
      updateOrderType(orderType);
      navigate("/onboarding");
    } else {
      toast.error(`${t("pos.review.toast.deliveryAddress")}`);
    }
  };

  const calculateTotalCost = (
    orders: IOrder[],
    aggregatedAccessories: ISelectedAccessory[]
  ): number => {
    const totalTerminalCost = orders.reduce((sum, order) => {
      if (order.selectedTerminal && order.selectedSubscription) {
        const oneTimeFee =
          order.selectedSubscription.Fees.find(
            (fee) => fee.Type === "OneTimeFee"
          )?.Price || 0;

        const buyFee =
          order.selectedSubscription.Fees.find((fee) => fee.Type === "BuyFee")
            ?.Price || 0;

        const terminalQuantity = order.selectedTerminal.quantity || 1;
        return sum + (oneTimeFee + buyFee) * terminalQuantity;
      }
      return sum;
    }, 0);

    const totalAccessoryCost = aggregatedAccessories.reduce(
      (sum, accessoryItem) => {
        const price =
          accessoryItem.accessory.Fees.find((fee) => fee.Type === "BuyFee")
            ?.Price || 0;
        return sum + price * accessoryItem.quantity;
      },
      0
    );

    return totalTerminalCost + totalAccessoryCost;
  };

  let totalAmount = calculateTotalCost(orders, aggregatedAccessories);
  let totalAmountVat = calculateTotalCost(orders, aggregatedAccessories) * 1.21;
  let VAT = totalAmountVat - totalAmount;

  if (!orders || orders.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center my-10">
        <div className="text-lg text-gray-700 mb-4">
          {t("pos.review.noOrder")}
        </div>
        <button
          onClick={() => navigate(`/pos/terminal`)}
          className="bg-primary-500 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded"
        >
          {t("pos.buttons.goBack")}{" "}
        </button>
      </div>
    );
  }
  return (
    <div className="container mx-auto px-4 py-8 pt-4 bg-white w-full md:max-w-3xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl">
      <h1 className=" text-center sm:text-start text-3xl font-bold text-gray-900 mb-2">
        {t("pos.review.title")}
      </h1>
      <p className="text-gray-600 text-center sm:text-start font-semibold">
        {" "}
        {t("pos.review.check")}{" "}
      </p>
      <div className="">
        {orders.map((order: IOrder) => (
          <div key={order.orderId}>
            {order.selectedTerminal && (
              <div className="border-b my-4">
                <OrderReviewProduct
                  orderId={order.orderId}
                  product={order.selectedTerminal}
                  subscription={order.selectedSubscription}
                  location={order.location}
                  onQuantityChange={(quantityChange) =>
                    updateQuantity(
                      "terminal",
                      order?.selectedTerminal?.InvoiceCode || "",
                      quantityChange,
                      order.orderId
                    )
                  }
                />
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="">
        {aggregatedAccessories?.map((accessory, index) => (
          <div className="border-b">
            <OrderReviewAccessory key={index} accessory={accessory} />
          </div>
        ))}
      </div>
      <div className="border-b">
        <OrderReviewBundle />
      </div>
      <div className="flex flex-col p-4 md:flex-row justify-between mt-5">
        <div className="mt-8 mb-10 sm:mb-0 sm:w-2/3">
          <h3 className="text-xl font-semibold mb-3">
            {t("pos.review.delivery")}
          </h3>
          <DeliveryLocation />
        </div>
        <div className="mt-4 md:mr-32">
          <div className="flex justify-center sm:justify-end items-center border-t-2 border-primary-600 pt-8 sm:border-t-0 sm:pt-0">
            <div className="flex flex-col  items-end">
              <div className="flex justify-between items-center w-full">
                <span className="text-lg text-black font-semibold">
                  {t("pos.review.excl")}
                </span>
                <span className="ml-4 text-lg font-medium">
                  €{formatPrice(totalAmount)}
                </span>
              </div>
              <div className="flex justify-between items-center w-full mt-1 border-b">
                <span className="text-lg text-black font-semibold">
                  {t("pos.review.vat")}
                </span>
                <span className="text-lg font-medium">€{formatPrice(VAT)}</span>
              </div>
              <div className="flex justify-between items-center w-full mt-1">
                <span className="text-lg flex justify-start text-black font-semibold">
                  {t("pos.review.total")}
                </span>
                <span className="text-lg font-bold">
                  €{formatPrice(totalAmountVat)}
                </span>
              </div>
              <span className="self-start mt-5 text-xs font-semibold">
                {t("pos.review.infoTotal")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 mb-4">
        <p>{t("pos.buttons.review.info")}</p>
      </div>
      <div className="flex justify-between space-x-4 mt-8">
        <button
          onClick={handleAddNewOrder}
          className="
          text-primary-500 font-semibold bg-black hover:bg-primary-500 hover:text-black
           rounded-md px-4 py-2 transition-colors duration-200 
          "
        >
          {t("pos.buttons.review.terminal")}
        </button>
        <button
          onClick={() => handleFinish()}
          className="px-4 py-2 bg-primary-500 text-black font-bold rounded-md hover:bg-primary-700"
        >
          {t("pos.buttons.review.account")}
        </button>
      </div>
    </div>
  );
};
export default OrderReview;
