import React, { useContext, useState } from "react";
import useCustomNavigate from "../../components/pos/hooks/useCustomNavigate";
import { t } from "i18next";
import { PosContext } from "../../context/PosContext";
import toast from "react-hot-toast";
import { validateTID } from "../../utils/validateTid";
import Modal from "../../components/pos/ui/Modal";
import Tooltip from "../../components/ui/Tooltip";

const MigrateTerminal: React.FC = () => {
  const navigate = useCustomNavigate();
  const { updateMigrate, migrate, updateSteps } = useContext(PosContext);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const [inputs, setInputs] = useState<string[]>(
    migrate.TIDs.length > 0 ? migrate.TIDs : [""]
  );
  const addInput = () => {
    setInputs([...inputs, ""]);
  };

  const removeInput = (index: number) => {
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };

  const handleInputChange = (index: number, newValue: string) => {
    const newInputs = [...inputs];
    newInputs[index] = newValue;
    setInputs(newInputs);
  };

  const handleContinue = () => {
    const nonEmptyTIDs = inputs.filter((input) => input.trim() !== "");
    const allTIDsValid = nonEmptyTIDs.every(validateTID);
    if (!allTIDsValid || !nonEmptyTIDs?.length) {
      toast.error(`${t("errors.toastError.TID")}`);
      return;
    }
    updateMigrate({ TIDs: nonEmptyTIDs });
    updateSteps({ migrateterminal: true });
    navigate("/pos/migrate/review");
  };
  const tidValid = inputs.every(validateTID);
  return (
    <div className="container mx-auto px-4 py-8 pt-4 bg-white w-full md:max-w-3xl lg:max-w-5xl xl:max-w-6xl 2xl:max-w-7xl">
      <div
        className="flex items-center text-primary-500 cursor-pointer mb-6"
        onClick={() => navigate("/pos/migrate/location")}
      >
        <svg
          className="w-6 h-6 mr-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 19l-7-7 7-7"
          ></path>
        </svg>
        {t("pos.buttons.thirdParty.hint")}
      </div>

      <div className="flex space-x-2">
        <h1 className="text-2xl font-bold mb-2 text-left relative">
          {t("pos.buttons.thirdParty.name")}
        </h1>
        <div className="-mt-2 lg:flex justify-start hidden">
          <Tooltip
            content={
              <div className="text-white w-80">
                {t("pos.buttons.thirdParty.info")}
              </div>
            }
            direction="right"
            tooltipClasses="bg-primary-600 text-white shadow-xl text-lg"
            arrowClasses="bg-primary-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 absolute text-primary-600 hover:text-primary-600 cursor-pointer"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
              />
            </svg>
          </Tooltip>
        </div>
      </div>
      <span className="text-gray-600 font-semibold lg:hidden">
        {t("pos.buttons.thirdParty.info")}
      </span>
      <p className=" text-primary-600 text-lg font-semibold mb-4">
        {t("pos.buttons.thirdParty.p1")}
      </p>
      <div className="grid grid-cols-1 lg:grid-cols-5">
        <div className="col-span-1 md:col-span-2">
          {inputs.map((value, index) => (
            <div key={index} className="flex mt-4 items-center space-x-2 ">
              <div className="relative flex w-72">
                <input
                  type="text"
                  placeholder={`${t("pos.buttons.thirdParty.tid")}`}
                  value={value}
                  className="border border-gray-300 rounded-lg p-2 w-72 focus:border-primary-500 pr-8"
                  onChange={(e) => handleInputChange(index, e.target.value)}
                />
                <div className="absolute inset-y-0 right-2 flex items-center">
                  {value &&
                    (validateTID(value) ? (
                      <span className="text-green-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="3"
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m4.5 12.75 6 6 9-13.5"
                          />
                        </svg>
                      </span>
                    ) : (
                      <span className="text-red-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="3"
                          stroke="currentColor"
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                          />
                        </svg>
                      </span>
                    ))}
                </div>
              </div>
              {index === inputs.length - 1 && tidValid && (
                <button
                  className="hover:bg-gray-300 rounded-lg p-2 transition-colors"
                  onClick={addInput}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </button>
              )}
              {index !== 0 && (
                <button
                  className=" hover:text-red-500 rounded-lg p-2 transition-colors"
                  onClick={() => removeInput(index)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                    />
                  </svg>
                </button>
              )}
            </div>
          ))}
        </div>
        <div className="flex flex-col col-span-1 md:col-span-3">
          <div className="mt-20">
            <div className="flex flex-wrap ml-20 sm:ml-0 justify-center gap-4">
              <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
                <img
                  src={require("../../images/pos/VerifoneP400.png")}
                  alt="Step 2"
                  className="w-44 md:w-40 lg:w-56 h-auto rounded-md"
                />
              </div>
              <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
                <img
                  src={require("../../images/pos/Yomani_ML.png")}
                  alt="Step 3"
                  className="w-44 md:w-40 lg:w-56 h-auto rounded-md"
                />
              </div>
              <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
                <img
                  src={require("../../images/pos/PAX_A_920.png")}
                  alt="Step 1"
                  className="w-32 -mt-7 md:w-28 xl:w-32 h-auto rounded-md"
                />
              </div>
            </div>
          </div>
          <div className="flex ml-8 sm:ml-0 mt-6 items-center justify-center sm:mt-2">
            <div className="flex space-x-2 hover:underline hover:cursor-pointer hover:text-primary-600">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
              </svg>

              <span onClick={handleOpenModal}>{t("pos.findTid.title")}</span>
            </div>
          </div>
          {isModalOpen && (
            <Modal onClose={handleCloseModal} className="max-w-lg">
              <div className="bg-white rounded-md sm:p-1 max-w-md mx-auto">
                <h2 className=" text-lg sm:text-2xl mt-6 sm:mt-0 font-bold mb-4 text-center text-primary-600">
                  {t("pos.findTid.help")}
                </h2>
                <p className="sm:mb-2 p-2 text-sm sm:text-base text-center font-semibold">
                  {t("pos.findTid.vendor")}:
                </p>
                <div className="grid grid-cols-2 p-6 gap-6 sm:gap-4">
                  {[
                    {
                      title: t("pos.findTid.ccv.title"),
                      description: t("pos.findTid.ccv.p1"),
                      link: "https://www.ccv.eu/nl/service/contact/",
                    },
                    {
                      title: t("pos.findTid.worldline.title"),
                      description: t("pos.findTid.worldline.p1"),
                      link: "https://support.worldline.com/nl-nl/home/merchants/contact.html",
                    },
                    {
                      title: t("pos.findTid.tsg.title"),
                      description: t("pos.findTid.tsg.p1"),
                      link: "https://www.tsg-solutions.com/nl/contact/",
                    },
                    {
                      title: t("pos.findTid.cm.title"),
                      description: t("pos.findTid.cm.p1"),
                      link: "https://www.cm.com/support/",
                    },
                  ].map((vendor, index) => (
                    <div
                      key={index}
                      className="bg-gray-100 rounded-lg p-4 flex flex-col justify-between items-center"
                    >
                      <h3 className="text-md sm:text-lg font-semibold mb-2">
                        {vendor.title}
                      </h3>
                      <p className="text-center text-sm sm:text-base">
                        {vendor.description}
                      </p>
                      <a
                        href={vendor.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mt-4 text-primary-500 text-sm sm:w-auto sm:text-base font-semibold bg-black hover:bg-primary-500 hover:text-black py-2 px-4 rounded-md transition duration-300 ease-in-out"
                      >
                        {t("pos.findTid.contact")}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </Modal>
          )}
        </div>
      </div>
      <div className="flex justify-end">
        <button
          onClick={handleContinue}
          className="mt-20 bg-primary-500 text-black font-bold py-2 px-4 rounded hover:bg-primary-600 transition duration-300"
        >
          {t("pos.buttons.continue.name")}
        </button>
      </div>
    </div>
  );
};

export default MigrateTerminal;
